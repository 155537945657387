import React, { FC, useContext } from "react";
import { TeaserProps, ColumnType } from "../generated-types";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { AspectRatio, Box, Flex, Heading, Text as ChakraText, useBreakpointValue } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { imageSizes, themable } from "~/foundation/Theme";
import { orientation } from "../base";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

const Teaser: FC<TeaserProps> = (props) => {
	const { params, rendering } = props;
	const imageLast: boolean = params.orientation === orientation.TextImage;
	const smallerPadding = params?.spacing === "none" ? true : false;

	if (rendering.customData?.teaserType === ColumnType.OneColumn) {
		return (
			<ContentWrapper py="0" pb={["16", null, "12"]}>
				<TeaserBaseWide itemId={rendering.dataSource} teaserProps={props} imageLast={imageLast} />
			</ContentWrapper>
		);
	}

	if (rendering.customData?.teaserType === ColumnType.TwoColumn) {
		return (
			<TeaserBaseSquareMd itemId={rendering.dataSource} teaserProps={props} />
		);
	}

	return (
		<Ribbon>
			<ContentWrapper
				py="0"
				pt={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
				pb={smallerPadding ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			>
				<TeaserBaseWide itemId={rendering.dataSource} teaserProps={props} imageLast={imageLast} />
			</ContentWrapper>
		</Ribbon>
	);
};

const TeaserBaseWide: FC<{ imageLast: boolean, teaserProps: TeaserProps, itemId?: string }> = ({ imageLast, teaserProps: { fields }, itemId: itemId, }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	//const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });

	return (
		<Flex alignItems="center" flexDirection={["column", null, imageLast ? "row-reverse" : "row"]}>
			<Box flexBasis={["100%", null, "50%"]} w={["100%", null, "50%"]}>
				{(fields?.teaserImage?.value || sitecoreContext.pageEditing) &&
					<AspectRatio ratio={16 / 9} sx={{
						".LazyLoad": {
							borderRadius: "lg",
							borderBottomRightRadius: "0"
						}
					}}>
						<sc.AdvanceImage
							field={fields?.teaserImage}
							itemId={itemId}
							fieldNames={["teaser image"]}
							sizes="(min-width: 1760px) 880px, (min-width: 1440px) 592px, (min-width: 769px) 346px, 100vw"
							srcSet={[imageSizes.teaser.landscape.sm, imageSizes.teaser.landscape.md, imageSizes.teaser.landscape.lg]}
							sx={{ maxWidth: "100%", minWidth: "100%" }}/>
					</AspectRatio>
				}
				{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
					<ChakraText variant="footerBody" maxW="482" mt={["4", null, "10"]} ms={["0", null, "12", null]} me={["4", null, "0", null]}>
						<sc.Text field={fields?.captionText} />
					</ChakraText>
				}
			</Box>
			<Box
				flex={["0 0 100%", null, "0 0 50%"]}
				w={["100%", null, "50%"]}
				ps={["0", null, "16", null, imageLast ? "6.25rem" : "6.875rem"]}
				pe={["0", null, "16", null, imageLast ? "6.875rem" : "6.25rem"]}>
				{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing) &&
					<Heading as="h3" size="xs" mt={["8", null, "0"]} mb={["4"]}>
						<sc.Text field={fields?.teaserHeadline}/>
					</Heading>
				}
				{(fields?.teaserDescription?.value || sitecoreContext.pageEditing) &&
					<ChakraText
						as="div"
						mt={["4"]}
						size="body"
						sx={{
							'a': {
								textDecoration: "underline"
							}
						}}>
						<sc.RichText field={fields?.teaserDescription} />
					</ChakraText>
				}
				{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing) &&
					<Box mt={["8"]}>
						<sc.Link field={fields?.teaserLink} variant="primary" size="md" color={theme.colors.ribbon.linkColor} />
					</Box>
				}
			</Box>
		</Flex>
	)
}

const TeaserBaseSquareMd: FC<{ teaserProps: TeaserProps, itemId?: string }> = ({ teaserProps: { fields }, itemId: itemId }) => {
	const { sitecoreContext } = useSitecoreContext();
	const [theme] = useContext(ColorThemeContext);
	const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });

	return (
		<Flex flexDirection="column" mx={["0", null, "12"]} flexGrow="1">
			{(fields?.teaserImage?.value || sitecoreContext.pageEditing) &&
				<Box mb="4">
					<AspectRatio ratio={1 / 1}>
						<sc.AdvanceImage
							field={fields?.teaserImage}
							itemId={itemId}
							fieldNames={["teaser image"]}
							sizes="(min-width: 1760px) 592px, (min-width: 769px) 482px, 100vw"
							srcSet={[imageSizes.teaser.square.sm, imageSizes.teaser.square.md, imageSizes.teaser.square.lg]}
							sx={{
								maxWidth: "100%",
								minWidth: "100%",
								borderRadius: "lg",
								borderBottomRightRadius: "0"
							}}/>
					</AspectRatio>
					{(fields?.captionText?.value || sitecoreContext.pageEditing) &&
						<ChakraText variant="footerBody" maxW="482" mt={["4", null, "10"]} ms={["0", null, "12", null]} me={["4", null, "0", null]}>
							<sc.Text field={fields?.captionText} />
						</ChakraText>
					}
				</Box>
			}
			<Box
				mx="0">
				{(fields?.teaserHeadline?.value || sitecoreContext.pageEditing) &&
					<Heading as="h3" size="xs" my="4">
						<sc.Text field={fields?.teaserHeadline}/>
					</Heading>
				}
				{(fields?.teaserDescription?.value || sitecoreContext.pageEditing) &&
					<ChakraText
						as="div"
						mt={["4"]}
						size="body"
						sx={{
							'a': {
								textDecoration: "underline"
							}
						}}>
						<sc.RichText field={fields?.teaserDescription} />
					</ChakraText>
				}
				{(fields?.teaserLink?.value.href || sitecoreContext.pageEditing) &&
					<Box mt={["6"]}>
						<sc.Link field={fields?.teaserLink} variant={isLargeViewport ? "primary" : "secondary"} size="md" color={theme.colors.ribbon.linkColor} />
					</Box>
				}
			</Box>
		</Flex>
	)
}

export default themable()(Teaser, props => props.rendering.customData?.teaserType !== ColumnType.Default);